/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Flex,
    HStack,
    Heading,
    SimpleGrid,
    Text,
    useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputControl from "../../components/Input";
import { useNavigate, useParams } from "react-router-dom";
import { BsListUl } from "react-icons/bs";
import { createSites, getSitesById, updateSites } from "../../services/SitesService";
function CreateSite() {
    const { id } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [siteData, setSiteData] = useState(
        {
            name: "",
            url: "",
        }
    )


    const [socialLink, setSocialLink] = useState(
        [{ name: "Facebook", link: "" },
        { name: "Twitter", link: "" },
        { name: "Instagram", link: "" },
        { name: "Linkindin", link: "" },
        { name: "Youtube", link: "" },
        ])



    const getBlogByIdHandler = async () => {
        const res = await getSitesById(id);
        if (res.status === 200) {
            const { data } = res.data;
            setSiteData(data[0])
            // setSocialLink(data[0].socialLink)
            setSocialLink(socialLink.map((initialLink) => {
                const givenLink = data[0].socialLink.find(
                    (givenLink) => givenLink.name === initialLink.name
                );
                return {
                    ...initialLink,
                    link: givenLink && givenLink.link !== null ? givenLink.link : initialLink.link,
                };
            }))

        }
    };

    useEffect(() => {
        if (id) {
            getBlogByIdHandler()
        }
    }, [id])



    const onSubmit = async () => {
        const { name, url } = siteData;
        const payload = { name, url, socialLink: socialLink };
        let res;
        if (id) {
            res = await updateSites(id, payload);
            if (res.status === 200) {
                toast({
                    title: "Blog updated.",
                    description: res?.data?.message || "something went wrong",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                navigate("/sites");
            } else {
                toast({
                    title: "Error.",
                    description: res?.response?.data?.message || "something went wrong",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } else {
            res = await createSites(payload);
            if (res.status === 200) {
                toast({
                    title: "Site created.",
                    description: res?.data?.message || "something went wrong",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                navigate("/sites");
            } else {
                toast({
                    title: "Error.",
                    description: res?.response?.data?.message || "something went wrong",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    };


    const handleLinkChange = (index, field, value) => {
        setSocialLink((prevSocialLinks) => {
            const updatedLinks = [...prevSocialLinks];
            updatedLinks[index][field] = value === null ? "" : value.trim();
            return updatedLinks;
        });
    };

    return (
        <>
            <Box
                boxShadow="base"
                p={5}
                background="#fff"
                display="flex"
                justifyContent="space-between"
                alignContent="center"
            >
                <Text fontSize="22px"> Sites - Create</Text>
                <Button
                    onClick={() => navigate(-1)}
                    leftIcon={<BsListUl />}
                    colorScheme="blue"
                    variant="solid"
                >
                    <Text fontStyle="italic">List</Text>
                </Button>
            </Box>
            <Box p={5} boxShadow="base" background="#fff" m={5}>
                <SimpleGrid columns={2} spacing={5} >
                    <Box>
                        <InputControl
                            label="Name"
                            placeholder="Name"
                            onChange={(e) =>
                                setSiteData((prev) => ({ ...prev, name: e.target.value }))
                            }
                            value={siteData.name}
                        />
                    </Box>
                    <Box>
                        <InputControl
                            label="Url"
                            placeholder="Url"
                            onChange={(e) =>
                                setSiteData((prev) => ({ ...prev, url: e.target.value }))
                            }
                            value={siteData.url}
                        />
                    </Box>
                </SimpleGrid>

                <Box
                    mt={16}
                    mb={16}
                >
                    <Heading fontSize="middium" textTransform="uppercase" mb={5}>
                        Social Link
                    </Heading>
                    <SimpleGrid columns={2} spacing={5} >
                        {console.log("siteData", siteData)}
                        {socialLink?.map((linkItem, index) => (
                            <Box key={index}>
                                <InputControl
                                    label={linkItem.name}
                                    placeholder={linkItem.name}
                                    onChange={(e) => handleLinkChange(index, "link", e.target.value)}
                                    value={linkItem.link}
                                />
                            </Box>
                        ))}

                    </SimpleGrid>
                </Box>

                <Flex justify="flex-end" mt={5}>
                    <HStack>
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            Submit
                        </Button>
                        <Button
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => navigate("/sites")}
                        >
                            Cancel
                        </Button>
                    </HStack>
                </Flex>
            </Box>
        </>

    )
}

export default CreateSite