import axios from "axios";

export const createCaseStudy = async (payload) => {
  try {
    const res = await axios.post(`create/case-study`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const fetchAllCaseStudy = async () => {
  try {
    const res = await axios.get(`list/case-study`);
    return res;
  } catch (error) {
    return error;
  }
};
export const deleteCaseStudyData = async (id) => {
  try {
    const res = await axios.delete(`delete/case-study/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};


export const updateCaseStudy = async (id, payload) => {
  try {
    const res = await axios.put(`update/case-study/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const getSingleCaseStudy = async (id) => {
  try {
    const res = await axios.get(`list/case-study/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};