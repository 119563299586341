import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import InputControl from "../../components/Input";
import Editor from "../../components/Editor";
import { useNavigate, useParams } from "react-router-dom";
import { uploadImage } from "../../services/GlobalService";
import {
  createBanner,
  fetchBannerById,
  updateBanner,
} from "../../services/BannerService";
import { BsListUl } from "react-icons/bs";

const AddBanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [bannerData, setbannerData] = useState({});

  const uploadImageHandler = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadImage(formData);
    console.log(res, "resData");
    if (res.status === 200) {
      return res.data.data.ImageUrl;
    }
  };

  const onSubmit = async () => {
    const { image, bannerHeading, name, bannerSubHeading, description } =
      bannerData;
    const payload = {
      name,
      image,
      bannerHeading,
      bannerSubHeading,
      description,
    };
    let res;
    if (id) {
      res = await updateBanner(id, payload);
    } else {
      res = await createBanner(payload);
    }

    if (res.status === 200) {
      toast({
        title: `Banner  ${id ? "updated" : "created"}.`,
        description: `Banner ${id ? "updated" : "created"} successfully!`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error.",
        description: "Something went wrong!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const fetchBannerByIdHandler = useCallback(async () => {
    const res = await fetchBannerById(id);
    console.log(res.data.data, "res.data.data");
    setbannerData(res.data.data[0]);
  }, [id]);

  useEffect(() => {
    if (id) fetchBannerByIdHandler();
  }, [id, fetchBannerByIdHandler]);

  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">Banner - Create</Text>
        <Button
          onClick={() => navigate(-1)}
          leftIcon={<BsListUl />}
          colorScheme="blue"
          variant="solid"
        >
          <Text fontStyle="italic">List</Text>
        </Button>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <Heading fontSize="medium" textTransform="uppercase" mb={3}>
          Create Banner
        </Heading>
        <Box mb={5}>
          <InputControl
            label="Name"
            placeholder="Enter name"
            onChange={(e) =>
              setbannerData((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            value={bannerData.name}
          />
        </Box>
        <Box mb={5}>
          <InputControl
            label="Image"
            type="file"
            onChange={async (e) => {
              const image = await uploadImageHandler(e.target.files[0]);
              setbannerData((prev) => ({ ...prev, image: image }));
            }}
          />
          {bannerData?.image && <Image src={bannerData?.image} height={100} />}
        </Box>
        <SimpleGrid columns={2} spacing={5}>
          <Box>
            <InputControl
              label="Banner Hader"
              placeholder="Enter banner header"
              onChange={(e) =>
                setbannerData((prev) => ({
                  ...prev,
                  bannerHeading: e.target.value,
                }))
              }
              value={bannerData?.bannerHeading}
            />
          </Box>
          <Box>
            <InputControl
              label="Banner Sub-Hader"
              placeholder="Enter banner sub header"
              onChange={(e) =>
                setbannerData((prev) => ({
                  ...prev,
                  bannerSubHeading: e.target.value,
                }))
              }
              value={bannerData?.bannerSubHeading}
            />
          </Box>
        </SimpleGrid>
        <Box mt={5}>
          <Text mb="8px" fontWeight="bold">
            Description
          </Text>
          <Editor
            onChange={(content, delta, source, editor) => {
              setbannerData((prev) => ({
                ...prev,
                description: editor.getHTML(),
              }));
            }}
            value={bannerData?.description}
            id="toolbar"
          />
        </Box>

        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/page/banner")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default AddBanner;
