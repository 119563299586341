import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiClipboard,
  FiImage,
  FiBox,
  FiFileText,
  FiTwitch,
  FiServer,
} from "react-icons/fi";
import { Link } from "react-router-dom";

const LinkItems = [
  { name: "Dashboard", icon: FiHome, path: "/dashboard" },
  { name: "Add Page", icon: FiClipboard, path: "/page/add" },
  { name: "Manage Contact Us", icon: FiTrendingUp, path: "/mange-contact-us" },
  { name: "Case Study", icon: FiCompass, path: "/case-study" },
  {
    name: "FAQs",
    icon: FiTwitch,
    path: "/faq",
    children: [
      {
        name: "FAQs",
        icon: FiTwitch,
        path: "#",
        children: [
          { name: "Add FAQs", icon: FiTwitch, path: "/faq/create" },
          {
            name: "FAQs List",
            icon: FiTwitch,
            path: "/faq/list",
          },
        ],
      },
    ],
  },
  { name: "Blogs", icon: FiFileText, path: "/blog" },
  { name: "Banner", icon: FiImage, path: "/banner" },
  {
    name: "Product",
    icon: FiBox,
    path: "/product",
    children: [
      {
        name: "Product",
        icon: FiBox,
        path: "#",
        children: [
          { name: "Product Master", icon: FiBox, path: "/product/master" },
          {
            name: "Product Details",
            icon: FiBox,
            path: "/product/details",
          },
        ],
      },
    ],
  },
  { name: "Sites", icon: FiServer, path: "/sites" },
];

const Sidebar = ({ onClose, ...rest }) => {
  const [activePath, setActivePath] = useState('')
  useEffect(() => {
    setActivePath(window.location.pathname.split('/')[1])
  }, [])

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="100vh"
      overflow={'auto'}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between" py={5}>
        <img src={require("../../assets/img/logo.png")} alt="logo"
          style={{
            width: "175px",
            objectFit: "contain"
          }}
        />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          path={link.path}
          childrenMenu={link?.children}
          activePath={activePath}
          onClick={() => {
            setActivePath(link.path.split('/')[1]);
            localStorage.setItem('url', link.path.split('/')[1])
          }}
        >
          {link.name}
        </NavItem>
      ))
      }
    </Box >
  );
};

export default Sidebar;

const NavItem = ({ icon, path, childrenMenu, children, activePath, ...rest }) => {
  if (childrenMenu) {
    return (
      <Accordion allowMultiple >
        {childrenMenu.map((link) => (
          <AccordionItem border={0} >
            <AccordionButton p={0} width={"100%"}>
              <Flex
                align="center"
                p="4"
                mx={4}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                {...rest}
                justifyContent={"space-between"}
                style={{
                  width: "100%",
                  fontSize: "15px",
                  backgroundColor: `${activePath === path.split('/')[1] ? "#005287" : ""}`,
                  color: `${activePath === path.split('/')[1] ? "white" : ""}`,
                }}
              >
                <div>
                  {link.icon && <Icon mr="3" fontSize="15" as={icon} />}
                  {link.name}
                </div>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel pb={4}>
              {link.children.map((subMenu) => (
                <Link to={subMenu.path} >
                  <Flex
                    align="center"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                    _hover={{
                      bg: "#005287",
                      color: "white",
                    }}
                    {...rest}
                    style={{ fontSize: "15px" }}
                  >
                    {icon && (
                      <Icon
                        mr="3"
                        fontSize="15"
                        _groupHover={{
                          color: "white",
                        }}
                        as={icon}
                      />
                    )}
                    {subMenu.name}
                  </Flex>
                </Link>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))
        }
      </Accordion >
    );
  } else {
    return (
      <Link to={path} className="cdjnc">
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "#005287",
            color: "white",
          }}
          {...rest}
          style={{
            fontSize: "15px",
            backgroundColor: `${activePath === path.split('/')[1] ? "#005287" : ""}`,
            color: `${activePath === path.split('/')[1] ? "white" : ""}`,
          }}

        >
          {icon && (
            <Icon
              mr="3"
              fontSize="15"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    );
  }
};
