import { AddIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchContactList } from "../../services/ContactService";

const ManageContactUs = () => {
  const navigate = useNavigate();
  const [contactList, setContactList] = useState([]);


  const fetchContactListHanlder = async () => {
    const res = await fetchContactList();
    console.log(res, "resData");
    setContactList(res.data.data);
  };

  useEffect(() => {
    fetchContactListHanlder();
  }, []);
  return (
    <>
      <Box>
        <Box
          boxShadow="base"
          p={5}
          background="#fff"
          display="flex"
          justifyContent="space-between"
          alignContent="center"
        >
          <Text fontSize="22px">Manage Contact Us - List</Text>
          {!contactList.length &&
            <Button
              onClick={() => navigate("/mange-contact-us/create")}
              leftIcon={<AddIcon />}
              colorScheme="blue"
              variant="solid"
            >
              Add New Page
            </Button>
          }
        </Box>
        <Box p={5}>
          <TableContainer background="#fff" boxShadow="base" p={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Sr. No.</Th>
                  <Th>Header</Th>
                  <Th textAlign="right"> Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {contactList.map((page, index) => (
                  <Tr>
                    <Td width="5%">{index + 1}</Td>

                    <Td>{page.header}</Td>

                    <Td textAlign="right">
                      <IconButton
                        aria-label="Search database"
                        onClick={() =>
                          navigate(`/mange-contact-us/edit/${page.id}`)
                        }
                        icon={<EditIcon />}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default ManageContactUs;
