import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Heading,
  SimpleGrid,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, Suspense, lazy } from "react";
import InputControl from "../../components/Input";

import { useNavigate, useParams } from "react-router-dom";
import {
  createMasterProject,
  getMasterProductById,
  updateMaster,
} from "../../services/ProjectService";
import { uploadImage } from "../../services/GlobalService";
import { useCallback } from "react";
import { BsListUl } from "react-icons/bs";

const Editor = lazy(() => import("../../components/Editor"));

const CreateMaster = () => {
  const { seoURL, id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [pageData, setPageData] = useState({});

  const onSubmit = async () => {
    const {
      icon,
      image,
      pageContentShortDescription,
      pageContentDiscription,
      seoURL,
      metaKeywords,
      title,
      metaDiscription,
      ogTag,
      name,
    } = pageData;
    const payload = {
      name,
      icon,
      imageName: image,
      pageContentShortDescription,
      pageContentDiscription,
      seoURL,
      metaKeywords,
      title,
      metaDiscription,
      ogTag,
      isFooterOne: false,
      isFooterTwo: false,
      isFooterThree: false,
      isFooterFour: false,
      isHeader: false,
      isPageOrder: 1,
    };
    let res;
    if (id) {
      delete payload.seoURL;
      res = await updateMaster(id, payload);
      if (res.status === 200) {
        toast({
          title: "Product updated.",
          description: res?.response?.data?.message || "something went wrong",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/product/master");
      } else {
        toast({
          title: "Error.",
          description: res?.response?.data?.message || "something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      res = await createMasterProject(payload);
      if (res.status === 200) {
        toast({
          title: "Product created.",
          description: res?.response?.data?.message || "something went wrong",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/product/master");
      } else {
        toast({
          title: "Error.",
          description: res?.response?.data?.message || "something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    console.log(res, "resData");
  };

  const uploadImageHandler = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadImage(formData);
    console.log(res, "resData");
    if (res?.status === 200) {
      return res?.data?.data?.ImageUrl;
    }
  };

  const getMasterProductByIdHandler = useCallback(async () => {
    const res = await getMasterProductById(seoURL);
    console.log(res, "resData");
    if (res?.status === 200) {
      const resData = res?.data?.masterData;
      console.log(resData, "resData");
      setPageData({
        ...pageData,
        name: resData?.name,
        pageContentShortDescription: resData?.pageContentShortDescription,
        pageContentDiscription: resData?.pageContentDiscription,
        seoURL: resData?.seoURL,
        metaKeywords: resData?.metaKeywords,
        title: resData?.title,
        metaDiscription: resData?.metaDiscription,
        ogTag: resData?.ogTag,
        icon: resData?.icon,
        // image: resData.imageName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seoURL]);

  useEffect(() => {
    if (seoURL) {
      getMasterProductByIdHandler();
    }
  }, [seoURL, getMasterProductByIdHandler]);

  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">Product Masters - Create</Text>
        <Button
          onClick={() => navigate(-1)}
          leftIcon={<BsListUl />}
          colorScheme="blue"
          variant="solid"
        >
          <Text fontStyle="italic">List</Text>
        </Button>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <Heading fontSize="2x2" mb={3}>
          Create Products Details
        </Heading>
        <SimpleGrid columns={3} spacing={5}>
          <Box>
            <InputControl
              label="Name"
              placeholder="Name"
              onChange={(e) =>
                setPageData((prev) => ({
                  ...prev,
                  name: e.target.value,
                  seoURL: e.target.value.toLowerCase().replace(/ /g, "-"),
                }))
              }
              value={pageData.name}
            />
          </Box>
          <Box>
            <InputControl
              label="Image"
              placeholder="Image"
              type="file"
              onChange={async (e) => {
                const image = await uploadImageHandler(e.target.files[0]);
                setPageData((prev) => ({ ...prev, image }));
              }}
            />
          </Box>
          <Box>
            <InputControl
              label="Icon"
              type="file"
              onChange={async (e) => {
                const image = await uploadImageHandler(e.target.files[0]);
                setPageData((prev) => ({ ...prev, icon: image }));
              }}
            />
          </Box>
        </SimpleGrid>

        <Box mt={5} mb={5}>
          <FormLabel>Pricing Short Description</FormLabel>
          <Suspense fallback={<div>Loading...</div>}>
            <Editor
              onChange={(content, delta, source, editor) => {
                setPageData((prev) => ({
                  ...prev,
                  pageContentShortDescription: editor.getHTML(),
                }));
              }}
              value={pageData?.pageContentShortDescription}
              id="toolbar1"
            />
          </Suspense>
        </Box>
        <Box mt={5} mb={5}>
          <FormLabel>Description</FormLabel>
          <Suspense fallback={<div>Loading...</div>}>
            <Editor
              onChange={(content, delta, source, editor) => {
                setPageData((prev) => ({
                  ...prev,
                  pageContentDiscription: editor.getHTML(),
                }));
              }}
              value={pageData?.pageContentDiscription}
              id="toolbar2"
            />
          </Suspense>
        </Box>
        <Box mt={5} mb={5}>
          <InputControl
            label="Seo Url"
            placeholder="Enter Seo Url"
            onChange={(e) =>
              setPageData((prev) => ({ ...prev, seoURL: e.target.value }))
            }
            value={pageData.seoURL}
          />
        </Box>
        <SimpleGrid columns={2} spacing={5}>
          <Box>
            <Text mb="8px">Meta Keyword</Text>
            <Textarea
              size="lg"
              rows={8}
              onChange={(e) =>
                setPageData((prev) => ({
                  ...prev,
                  metaKeywords: e.target.value,
                }))
              }
              value={pageData.metaKeywords}
            />
          </Box>
          <Box>
            <InputControl
              label="Title"
              placeholder="TITLE"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, title: e.target.value }))
              }
              value={pageData.title}
            />
          </Box>
          <Box>
            <Text mb="8px">Meta Description</Text>
            <Textarea
              size="lg"
              rows={8}
              onChange={(e) =>
                setPageData((prev) => ({
                  ...prev,
                  metaDiscription: e.target.value,
                }))
              }
              value={pageData.metaDiscription}
            />
          </Box>
          <Box>
            <Text mb="8px">OG TAG</Text>
            <Textarea
              size="lg"
              rows={8}
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, ogTag: e.target.value }))
              }
              value={pageData.ogTag}
            />
          </Box>
        </SimpleGrid>

        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/product/master")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default CreateMaster;
