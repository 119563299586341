import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  IconButton,
  Text,
  Textarea,
  useToast,
  Heading,
} from "@chakra-ui/react";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import InputControl from "../../components/Input";
import Editor from "../../components/Editor";
import { useNavigate, useParams } from "react-router-dom";
import {
  createPage,
  getPageById,
  updatePage,
} from "../../services/PageService";
import { uploadImage } from "../../services/GlobalService";
import { BsListUl } from "react-icons/bs";
import SitesSelector from "../../components/SitesSelector";

const CreateForm = () => {
  const { seoURL, id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [pageData, setPageData] = useState({});
  const [productContent, setProductContent] = useState([
    {
      imageName: "",
      image: "",
      imageTitle: "",
      altTag: "",
      webPageContent: "",
      sites: [],
    },
  ]);

  const [showPageContent, setShowPageContent] = useState(false);

  const onSubmit = async () => {
    const {
      name,
      seoURL,
      subTitle,
      title,
      metaKeywords,
      metaDiscription,
      ogTag,
      imageFile,
      imageName,
      imageTitle,
      altTag,
      pageContent,
      isFooterOne,
      isFooterTwo,
      isFooterThree,
      isFooterFour,
      isHeader,
    } = pageData;
    const payload = {
      name,
      seoURL,
      subTitle,
      title,
      metaKeywords,
      metaDiscription,
      ogTag,
      imageName,
      imageFile,
      imageTitle,
      altTag,
      pageContent,
      isFooterOne,
      isFooterTwo,
      isFooterThree,
      isFooterFour,
      isHeader,
      webPageContent: productContent,
    };
    let res;
    if (id) {
      delete payload.seoURL;
      res = await updatePage(id, payload);

      if (res.status === 200) {
        toast({
          title: "Page Updated.",
          description: res?.data?.message || "something went wrong",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/page/add");
      } else {
        toast({
          title: "Error.",
          description: res?.response?.data?.message || "something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      res = await createPage(payload);
      if (res.status === 200) {
        toast({
          title: "Page created.",
          description: res.data.message || "something went wrong",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/page/add");
      } else {
        toast({
          title: "Error.",
          description: res?.response?.data?.message || "something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    console.log(res, "resData");
  };
  useEffect(() => {
    const localPageData = localStorage.getItem("pageData");
    if (id) {
      if (localPageData) {
        const localPageDataObj = JSON.parse(localPageData);
        const pageData = localPageDataObj.find((page) => page.seoURL === id);
        console.log(pageData);
        setPageData(pageData);
      }
    }
  }, [id]);

  const getPageByIdHandler = useCallback(async () => {
    const res = await getPageById(seoURL);
    console.log(res, "resData");
    if (res.status === 200) {
      const resData = res.data.data;
      setPageData({
        ...pageData,
        name: resData.name,
        seoURL: resData.seoURL,
        subTitle: resData.subTitle,
        title: resData.title,
        metaKeywords: resData.metaKeywords,
        metaDiscription: resData.metaDiscription,
        ogTag: resData.ogTag,
        imageName: resData.imageName,
        imageTitle: resData.imageTitle,
        altTag: resData.altTag,
        pageContent: resData.pageContent,
        imageFile: resData.imageFile,
        isFooterOne: resData.isFooterOne,
        isFooterTwo: resData.isFooterTwo,
        isFooterThree: resData.isFooterThree,
        isFooterFour: resData.isFooterFour,
        isHeader: resData.isHeader,
      });
      if (resData?.webPageContent?.length) {
        setShowPageContent(true);
        setProductContent(resData.webPageContent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seoURL]);

  useEffect(() => {
    if (id) {
      getPageByIdHandler();
    }
  }, [id, getPageByIdHandler]);

  const uploadImageHandler = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadImage(formData);
    console.log(res, "resData");
    if (res.status === 200) {
      setPageData((prev) => ({ ...prev, imageFile: res.data.data.ImageUrl }));
    }
  };

  const addProductContent = () => {
    setProductContent([
      ...productContent,
      {
        imageName: "",
        image: "",
        imageTitle: "",
        altTag: "",
        webPageContent: "",
        sites: [],
      },
    ]);
  };

  const deleteProductContent = (index) => {
    const newProductContent = productContent.filter((term, i) => i !== index);
    setProductContent(newProductContent);
  };

  const uploadProductImageHandler = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadImage(formData);
    console.log(res, "resData");
    if (res.status === 200) {
      return res.data.data.ImageUrl;
    }
  };

  const handleSites = (e, index = 0) => {
    console.log(e, "handleSites");
    setProductContent((prev) => {
      const newFaqList = [...prev];
      newFaqList[index].sites = e;
      return newFaqList;
    });
  };

  return (
    <>
      {console.log(pageData, productContent, "pageData")}
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">Research & Publication - List</Text>

        <Button
          onClick={() => navigate(-1)}
          leftIcon={<BsListUl />}
          colorScheme="blue"
          variant="solid"
        >
          <Text fontStyle="italic">List</Text>
        </Button>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <SimpleGrid columns={2} spacing={5}>
          <Box>
            <InputControl
              label="Name"
              placeholder="Name"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, name: e.target.value }))
              }
              value={pageData.name}
            />
          </Box>
          <Box>
            <InputControl
              label="URL"
              placeholder="SEO URL"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, seoURL: e.target.value }))
              }
              value={pageData.seoURL}
            />
          </Box>
          <Box>
            <InputControl
              label="Sub-Title"
              placeholder="SUB-TITLE"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, subTitle: e.target.value }))
              }
              value={pageData.subTitle}
            />
          </Box>
          <Box>
            <InputControl
              label="Title"
              placeholder="TITLE"
              onChange={(e) =>
                setPageData((prev) => ({
                  ...prev,
                  title: e.target.value,
                  seoURL: e.target.value.toLowerCase().replace(/ /g, "-"),
                }))
              }
              value={pageData.title}
            />
          </Box>
          <Box>
            <Text mb="8px">Meta Keyword</Text>
            <Textarea
              size="lg"
              rows={8}
              onChange={(e) =>
                setPageData((prev) => ({
                  ...prev,
                  metaKeywords: e.target.value,
                }))
              }
              value={pageData.metaKeywords}
            />
          </Box>
          <Box>
            <Text mb="8px">Meta Description</Text>
            <Textarea
              size="lg"
              rows={8}
              onChange={(e) =>
                setPageData((prev) => ({
                  ...prev,
                  metaDiscription: e.target.value,
                }))
              }
              value={pageData.metaDiscription}
            />
          </Box>
          <Box>
            <Text mb="8px">OG TAG</Text>
            <Textarea
              size="lg"
              rows={8}
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, ogTag: e.target.value }))
              }
              value={pageData.ogTag}
            />
          </Box>
          <Box></Box>
          <Box>
            <InputControl
              label="Image Name"
              placeholder="Image Name"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, imageName: e.target.value }))
              }
              value={pageData.imageName}
            />
          </Box>
          <Box>
            <InputControl
              type="file"
              label="Image File"
              onChange={(e) => {
                uploadImageHandler(e.target.files[0]);
              }}
              accept="image/png, image/gif, image/jpeg"
            />
            {pageData?.imageFile && (
              <Image src={pageData?.imageFile} height={100} />
            )}
          </Box>
          <Box>
            <InputControl
              label="Image Title"
              placeholder="Image Title"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, imageTitle: e.target.value }))
              }
              value={pageData.imageTitle}
            />
          </Box>
          <Box>
            <InputControl
              label="alt Tag"
              placeholder="alt Tag"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, altTag: e.target.value }))
              }
              value={pageData.altTag}
            />
          </Box>
        </SimpleGrid>

        <Box mt={5}>
          <Editor
            onChange={(content, delta, source, editor) => {
              setPageData((prev) => ({
                ...prev,
                pageContent: editor.getHTML(),
              }));
            }}
            value={pageData?.pageContent}
            id="toolbar"
          />
        </Box>

        <Box>
          <SitesSelector
            selectedSite={productContent?.[0].sites}
            handleSites={(e) => handleSites(e)}
          />
          <Stack
            spacing={5}
            mt={10}
            justifyContent={"space-between"}
            direction="row"
          >
            <div>
              <input
                id="isHeader"
                type="checkbox"
                checked={pageData?.isHeader}
                onChange={(e) =>
                  setPageData((prev) => ({
                    ...prev,
                    isHeader: e.target.checked,
                  }))
                }
              />
              <label style={{ cursor: "pointer" }} htmlFor="isHeader">
                {" "}
                Is Header
              </label>
            </div>
          </Stack>
        </Box>
        <Box>
          <Stack spacing={5} mt={10} wrap={"wrap"} direction="row">
            <div>
              <input
                id="isFooterOne"
                type="checkbox"
                checked={pageData?.isFooterOne}
                onChange={(e) =>
                  setPageData((prev) => ({
                    ...prev,
                    isFooterOne: e.target.checked,
                  }))
                }
              />
              <label style={{ cursor: "pointer" }} htmlFor="isFooterOne">
                {" "}
                Is FooterOne
              </label>
            </div>
            <div>
              <input
                id="isFooterTwo"
                type="checkbox"
                checked={pageData?.isFooterTwo}
                onChange={(e) =>
                  setPageData((prev) => ({
                    ...prev,
                    isFooterTwo: e.target.checked,
                  }))
                }
              />
              <label style={{ cursor: "pointer" }} htmlFor="isFooterTwo">
                {" "}
                Is FooterTwo
              </label>
            </div>
            <div>
              <input
                id="isFooterThree"
                type="checkbox"
                checked={pageData?.isFooterThree}
                onChange={(e) =>
                  setPageData((prev) => ({
                    ...prev,
                    isFooterThree: e.target.checked,
                  }))
                }
              />
              <label style={{ cursor: "pointer" }} htmlFor="isFooterThree">
                {" "}
                Is FooterThree
              </label>
            </div>
            <div>
              <input
                id="isFooterFour"
                type="checkbox"
                checked={pageData?.isFooterFour}
                onChange={(e) =>
                  setPageData((prev) => ({
                    ...prev,
                    isFooterFour: e.target.checked,
                  }))
                }
              />
              <label style={{ cursor: "pointer" }} htmlFor="isFooterFour">
                {" "}
                Is FooterFour
              </label>
            </div>
          </Stack>
        </Box>

        <Box
          mt={5}
          mb={5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="large" textTransform="uppercase">
            Page Content
          </Heading>
          <IconButton
            onClick={
              !showPageContent
                ? () => setShowPageContent(true)
                : addProductContent
            }
            aria-label="Search database"
            icon={<AddIcon />}
          />
        </Box>
        {console.log("productContent", productContent)}
        {showPageContent
          ? productContent?.map((PC, index) => (
              <Box
                p={5}
                boxShadow="base"
                background="#fff"
                m={5}
                position="relative"
              >
                <IconButton
                  position="absolute"
                  right="5px"
                  top="5px"
                  onClick={() => deleteProductContent(index)}
                  aria-label="Search database"
                  icon={<DeleteIcon />}
                />
                <SimpleGrid columns={2} spacing={5} paddingTop={35}>
                  <Box>
                    <InputControl
                      label="Image Name #1"
                      placeholder="Name"
                      onChange={(e) => {
                        const newProductTerms = [...productContent];
                        newProductTerms[index].imageName = e.target.value;
                        setProductContent(newProductTerms);
                      }}
                      value={productContent[index]?.imageName}
                    />
                  </Box>
                  <Box>
                    <InputControl
                      label="Image File #1"
                      type="file"
                      placeholder="Image Name"
                      onChange={async (e) => {
                        const image = await uploadProductImageHandler(
                          e.target.files[0]
                        );
                        const newProductTerms = [...productContent];
                        newProductTerms[index].image = image;
                        setProductContent(newProductTerms);
                      }}
                    />
                    {productContent?.image && (
                      <Image src={productContent[index]?.image} height={100} />
                    )}
                  </Box>
                  <Box>
                    <InputControl
                      label="Image Title #1"
                      placeholder="Enter Image Title"
                      onChange={(e) => {
                        const newProductTerms = [...productContent];
                        newProductTerms[index].imageTitle = e.target.value;
                        setProductContent(newProductTerms);
                      }}
                      value={productContent[index]?.imageTitle}
                    />
                  </Box>
                  <Box>
                    <InputControl
                      label="Alt Tag #1"
                      placeholder="Enter Alt Tag "
                      onChange={(e) => {
                        const newProductTerms = [...productContent];
                        newProductTerms[index].altTag = e.target.value;
                        setProductContent(newProductTerms);
                      }}
                      value={productContent[index]?.altTag}
                    />
                  </Box>
                </SimpleGrid>

                <Box mt={5}>
                  <Text mb="8px">Content #1</Text>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Editor
                      onChange={(content, delta, source, editor) => {
                        const newProductTerms = [...productContent];
                        newProductTerms[index].webPageContent =
                          editor?.getHTML();
                        setProductContent(newProductTerms);
                      }}
                      value={productContent[index]?.webPageContent}
                      id={`toolbar_${index}`}
                    />
                  </Suspense>
                </Box>
              </Box>
            ))
          : ""}

        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/page/add")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default CreateForm;
