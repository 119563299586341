import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Textarea,
  Text,
  useToast,
  Image,
  Stack,
  FormLabel,
  Divider,
  // Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputControl from "../../components/Input";
import { useNavigate, useParams } from "react-router-dom";
import {
  createBlog,
  getAllBlog,
  getBlogById,
  updateBlog,
} from "../../services/BlogService";
import { uploadImage } from "../../services/GlobalService";
import { useCallback } from "react";
import { BsListUl } from "react-icons/bs";
import { getAllSites } from "../../services/SitesService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SunCustomEditor from "../../components/SunEditor/Index";
import SitesSelector from "../../components/SitesSelector";

const animatedComponents = makeAnimated();
const CreateBlog = () => {
  const { seoURL, id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [blogData, setBlogData] = useState({
    blogContent: {
      imageName: "",
      image: "",
      imageTitle: "",
      altTag: "",
      blogContent: "",
    },
    related_blogs: [],
    relatedblogsList: [],
    sites: [],
    metaDetails: {},
  });
  const [showBlogContent, setShowBlogContent] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState([]);

  const [selectedOption, setSelectedOption] = useState([]);
  const [metaDetails, setMetaDetails] = useState({});
  console.log(selectedBlog, "selectedBlog");
  useEffect(() => {
    getBlogData();
    getSiteList();
  }, []);

  const getBlogData = async () => {
    const res = await getAllBlog();
    if (res) {
      const resData = res.data.data;
      let formattedData = resData.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setBlogList(formattedData);
    }
  };

  const getSiteList = async () => {
    const res = await getAllSites();
    if (res) {
      const resData = res.data.data;
      let formattedData = resData.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSiteList(formattedData);
    }
  };

  const onSubmit = async () => {
    const {
      name,
      seoURL,
      subTitle,
      title,
      metaKeywords,
      metaDiscription,
      ogTag,
      image,
      imageName,
      imageTitle,
      altTag,
      pageContent,
      blogContent,
      is_featured,
      is_home,
      is_latest_blog,
      related_blogs,
      sites,
    } = blogData;
    const payload = {
      name,
      seoURL,
      image,
      header: "header",
      title,
      subTitle,
      metaKeywords,
      metaDiscription,
      ogTag,
      imageName,
      imageTitle,
      altTag,
      pageContent,
      blogContent,
      is_featured,
      is_home,
      is_latest_blog,
      related_blogs,
      sites,
      metaDetails: metaDetails,
    };
    debugger;
    let res;
    if (id) {
      delete payload.seoURL;
      res = await updateBlog(id, payload);
      if (res.status === 200) {
        toast({
          title: "Blog updated.",
          description: res?.data?.message || "something went wrong",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/blog");
      } else {
        toast({
          title: "Error.",
          description: res?.response?.data?.message || "something went wrong",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      res = await createBlog(payload);

      if (res.status === 200) {
        toast({
          title: "Blog created.",
          description: res?.data?.message || "something went wrong",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/blog");
      } else {
        toast({
          title: "Error.",
          description: res?.response?.data?.message || "something went wrong",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const uploadImageHandler = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadImage(formData);
    console.log(res, "resData");
    if (res.status === 200) {
      return res.data.data.ImageUrl;
    }
  };

  const getBlogByIdHandler = useCallback(async () => {
    const res = await getBlogById(seoURL);
    if (res.status === 200) {
      const { data } = res.data;
      setShowBlogContent(true);
      setBlogData({
        ...data,
        related_blogs: data.related_blogs.map((item) => {
          return item.id;
        }),
        sites: data.sites.map((item) => {
          return item.id;
        }),
      });
      let filterSite = data.sites.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSelectedSite(filterSite);
      setSelectedOption(filterSite);

      let filterBlog = data.related_blogs.map((item) => ({
        value: item.id,
        label: item.title,
      }));
      setSelectedBlog(filterBlog);
      setMetaDetails(data.metaDetails);
    }
  }, [seoURL]);

  useEffect(() => {
    if (seoURL) {
      getBlogByIdHandler();
    }
  }, [seoURL, getBlogByIdHandler]);

  const handleSites = (e) => {
    // remove option and metaDetails
    const removedOptions = selectedOption.filter(
      (option) => !e.map((opt) => opt.label).includes(option.label)
    );
    if (removedOptions.length) {
      const newMetaDetails = { ...metaDetails };
      removedOptions.forEach((removedOption) => {
        const label = removedOption.label;
        debugger;
        delete newMetaDetails[label];
      });
      setMetaDetails(newMetaDetails);
    }

    setSelectedOption(e);
    setSelectedSite(e);

    if (e.length) {
      setBlogData((prev) => ({
        ...prev,
        sites: e.map((item) => item.value),
      }));
    } else {
      setBlogData((prev) => ({
        ...prev,
        sites: [],
      }));
    }
  };

  const handleBlog = (e) => {
    setSelectedBlog(e);
    if (e.length) {
      setBlogData((prev) => ({
        ...prev,
        related_blogs: e.map((item) => item.value),
      }));
    } else {
      setBlogData((prev) => ({
        ...prev,
        related_blogs: [],
      }));
    }
  };

  // Handle changes in the input fields and update the metaDetails state accordingly
  const handleInputChange = (key, field, value) => {
    setMetaDetails((prevMetaDetails) => ({
      ...prevMetaDetails,
      [key]: {
        ...prevMetaDetails[key],
        [field]: value,
      },
    }));
  };

  return (
    <>
      {console.log("metaDetails", metaDetails)}
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">Blogs - Create</Text>
        <Button
          onClick={() => navigate(-1)}
          leftIcon={<BsListUl />}
          colorScheme="blue"
          variant="solid"
        >
          <Text fontStyle="italic">List</Text>
        </Button>
      </Box>
      <Box p={5} boxShadow="base">
        <Box p={5} boxShadow="base" background="#fff">
          <SimpleGrid columns={2} spacing={5}>
            <Box>
              <InputControl
                label="Name"
                placeholder="Name"
                onChange={(e) =>
                  setBlogData((prev) => ({ ...prev, name: e.target.value }))
                }
                value={blogData.name}
              />
            </Box>
            <Box>
              <InputControl
                label="URL"
                placeholder="SEO URL"
                onChange={(e) =>
                  setBlogData((prev) => ({ ...prev, seoURL: e.target.value }))
                }
                value={blogData.seoURL}
              />
            </Box>
            <Box>
              <InputControl
                label="Author"
                placeholder="AUTHOR"
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    subTitle: e.target.value,
                  }))
                }
                value={blogData.subTitle}
              />
            </Box>
            <Box>
              <InputControl
                label="Title"
                placeholder="TITLE"
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    title: e.target.value,
                    seoURL: e.target.value.toLowerCase().replace(/ /g, "-"),
                  }))
                }
                value={blogData.title}
              />
            </Box>
            <Box>
              <Text mb="8px">Meta Keyword</Text>
              <Textarea
                size="lg"
                rows={8}
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    metaKeywords: e.target.value,
                  }))
                }
                value={blogData.metaKeywords}
              />
            </Box>
            <Box>
              <Text mb="8px">Meta Description</Text>
              <Textarea
                size="lg"
                rows={8}
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    metaDiscription: e.target.value,
                  }))
                }
                value={blogData.metaDiscription}
              />
            </Box>
            <Box>
              <Text mb="8px">OG TAG</Text>
              <Textarea
                size="lg"
                rows={8}
                onChange={(e) =>
                  setBlogData((prev) => ({ ...prev, ogTag: e.target.value }))
                }
                value={blogData.ogTag}
              />
            </Box>
            <Box></Box>
            <Box>
              <InputControl
                label="Image Name"
                placeholder="Image Name"
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    imageName: e.target.value,
                  }))
                }
                value={blogData.imageName}
              />
            </Box>
            <Box>
              <InputControl
                type="file"
                label="Image File"
                onChange={async (e) => {
                  const image = await uploadImageHandler(e.target.files[0]);
                  setBlogData((prev) => ({ ...prev, image }));
                }}
              />
              {blogData?.image && <Image src={blogData?.image} height={100} />}
            </Box>
            <Box>
              <InputControl
                label="Image Title"
                placeholder="Image Title"
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    imageTitle: e.target.value,
                  }))
                }
                value={blogData.imageTitle}
              />
            </Box>
            <Box>
              <InputControl
                label="alt Tag"
                placeholder="alt Tag"
                onChange={(e) =>
                  setBlogData((prev) => ({ ...prev, altTag: e.target.value }))
                }
                value={blogData.altTag}
              />
            </Box>
          </SimpleGrid>

          <Box mt={5}>
            <SunCustomEditor
              onChange={(content) => {
                setBlogData((prev) => ({
                  ...prev,
                  pageContent: content,
                }));
              }}
              value={blogData?.pageContent}
              id="toolbar"
            />
          </Box>
          <Stack
            spacing={5}
            mt={10}
            justifyContent={"space-between"}
            direction="row"
          >
            <div>
              <input
                id="is_featured"
                type="checkbox"
                checked={blogData?.is_featured}
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    is_featured: e.target.checked,
                  }))
                }
              />
              <label htmlFor="is_featured"> Is Featured</label>
            </div>
            <div>
              <input
                id="is_home"
                type="checkbox"
                checked={blogData?.is_home}
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    is_home: e.target.checked,
                  }))
                }
              />
              <label htmlFor="is_home"> Is Home</label>
            </div>
            <div>
              <input
                id="is_latest_blog"
                type="checkbox"
                checked={blogData?.is_latest_blog}
                onChange={(e) =>
                  setBlogData((prev) => ({
                    ...prev,
                    is_latest_blog: e.target.checked,
                  }))
                }
              />
              <label htmlFor="is_latest_blog"> Is Latest Blog</label>
            </div>
          </Stack>
          <Box
            mt={16}
            mb={10}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            flexDirection={"column"}
          >
            <Heading fontSize="large" textTransform="uppercase" mb={8}>
              Blog Content
            </Heading>
            <Box position="relative" style={{ width: "100%" }}>
              <SimpleGrid columns={2} spacing={5}>
                <Box>
                  <InputControl
                    label="Image Name #1"
                    placeholder="Name"
                    onChange={(e) =>
                      setBlogData((prev) => ({
                        ...prev,
                        blogContent: {
                          ...prev.blogContent,
                          imageName: e.target.value,
                        },
                      }))
                    }
                    value={blogData.blogContent.imageName}
                  />
                </Box>
                <Box>
                  <InputControl
                    label="Image File #1"
                    type="file"
                    placeholder="Image Name"
                    onChange={async (e) => {
                      const image = await uploadImageHandler(e.target.files[0]);
                      setBlogData((prev) => ({
                        ...prev,
                        blogContent: {
                          ...prev.blogContent,
                          image: image,
                        },
                      }));
                    }}
                  />
                  {blogData?.blogContent?.image && (
                    <Image src={blogData?.blogContent?.image} height={100} />
                  )}
                </Box>
                <Box>
                  <InputControl
                    label="Image Title #1"
                    placeholder="Enter Image Title"
                    onChange={(e) =>
                      setBlogData((prev) => ({
                        ...prev,
                        blogContent: {
                          ...prev.blogContent,
                          imageTitle: e.target.value,
                        },
                      }))
                    }
                    value={blogData.blogContent.imageTitle}
                  />
                </Box>
                <Box>
                  <InputControl
                    label="Alt Tag #1"
                    placeholder="Enter Alt Tag "
                    onChange={(e) =>
                      setBlogData((prev) => ({
                        ...prev,
                        blogContent: {
                          ...prev.blogContent,
                          altTag: e.target.value,
                        },
                      }))
                    }
                    value={blogData.blogContent.altTag}
                  />
                </Box>
              </SimpleGrid>

              <Box mt={5}>
                <Text mb="8px">Content #1</Text>
                <SunCustomEditor
                  onChange={(content) => {
                    setBlogData((prev) => ({
                      ...prev,
                      blogContent: {
                        ...prev.blogContent,
                        blogContent: content,
                      },
                    }));
                  }}
                  value={blogData?.blogContent?.blogContent}
                  id="content_1"
                />
              </Box>
            </Box>
          </Box>

          <Box mt={15} mb={7}>
            {/* <FormLabel>Sites</FormLabel>
            <Select
              value={selectedSite}
              options={siteList}
              components={animatedComponents}
              isMulti
              onChange={(e) => handleSites(e)}
            // onMenuClose={handleMenuClose}
            /> */}
            <SitesSelector
              selectedSite={selectedSite}
              handleSites={handleSites}
            />

            <SimpleGrid columns={2} spacing={5}>
              {selectedOption.map((selectedOption) => {
                const key = selectedOption.label;
                return (
                  <Box key={key} mt={5}>
                    <Heading fontSize="large" textTransform="uppercase" mb={3}>
                      {key}
                    </Heading>
                    <Box mb={3}>
                      <Text mb="8px">Meta Keyword</Text>
                      <Textarea
                        size="lg"
                        rows={8}
                        value={metaDetails[key]?.metaKeywords || ""}
                        onChange={(e) =>
                          handleInputChange(key, "metaKeywords", e.target.value)
                        }
                      />
                    </Box>
                    <Box mb={3}>
                      <Text mb="8px">Meta Description</Text>
                      <Textarea
                        size="lg"
                        rows={8}
                        onChange={(e) =>
                          handleInputChange(
                            key,
                            "metaDescription",
                            e.target.value
                          )
                        }
                        value={metaDetails[key]?.metaDescription || ""}
                      />
                    </Box>
                    <Box mb={3}>
                      <Text mb="8px">OG TAG</Text>
                      <Textarea
                        size="lg"
                        rows={8}
                        onChange={(e) =>
                          handleInputChange(key, "ogTag", e.target.value)
                        }
                        value={metaDetails[key]?.ogTag || ""}
                      />
                    </Box>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>

          <Divider orientation="horizontal" />

          <Box mt={15}>
            <FormLabel>Related Blogs</FormLabel>
            <Select
              value={selectedBlog}
              options={blogList}
              components={animatedComponents}
              isMulti
              onChange={(e) => handleBlog(e)}
            />
          </Box>

          <Flex justify="flex-end" mt={5}>
            <HStack>
              <Button
                colorScheme="blue"
                variant="solid"
                onClick={() => {
                  onSubmit();
                }}
              >
                Submit
              </Button>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => navigate("/blog")}
              >
                Cancel
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default CreateBlog;
