import axios from "axios";

export const createSites = async (payload) => {
  try {
    const res = await axios.post(`sites`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const updateSites = async (id, payload) => {
  try {
    const res = await axios.put(`sites/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllSites = async () => {
  try {
    const res = await axios.get(`sites`);
    return res;
  } catch (error) {
    return error;
  }
};
export const deleteSites = async (id) => {
  try {
    const res = await axios.delete(`sites/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
export const getSitesById = async (id) => {
  try {
    const res = await axios.get(`sites/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
