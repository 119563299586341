import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputControl from "../../components/Input";
import Editor from "../../components/Editor";
import { useNavigate, useParams } from "react-router-dom";
import { uploadImage } from "../../services/GlobalService";
import {
  createCaseStudy,
  getSingleCaseStudy,
  updateCaseStudy,
} from "../../services/CaseStudyService";
import { BsListUl } from "react-icons/bs";
import SitesSelector from "../../components/SitesSelector";

const AddCaseStudy = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [caseStudyData, setCaseStudyData] = useState({});

  const uploadImageHandler = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadImage(formData);
    console.log(res, "resData");
    if (res.status === 200) {
      return res.data.data.ImageUrl;
    }
  };

  useEffect(() => {
    if (id) {
      getCaseStudy();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getCaseStudy = async () => {
    const res = await getSingleCaseStudy(id);
    console.log("res", res.data.data[0]);
    setCaseStudyData(res.data.data[0]);
  };

  const onSubmit = async () => {
    const {
      heading,
      date,
      name,
      file,
      shortDescription,
      is_case,
      is_whitepaper,
      sites,
    } = caseStudyData;
    const payload = {
      heading,
      date,
      name,
      file,
      is_case,
      is_whitepaper,
      short_description: shortDescription,
      sites,
    };
    if (id) {
      const res = await updateCaseStudy(id, payload);

      if (res.status === 200) {
        toast({
          title: "Case Study Update.",
          description: "Case Study Update successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/case-study");
      } else {
        toast({
          title: "Error.",
          description: "Something went wrong!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      const res = await createCaseStudy(payload);
      if (res.status === 200) {
        toast({
          title: "Case Study created.",
          description: "Case Study created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error.",
          description: "Something went wrong!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleSites = (e) => {
    console.log(e, "handleSites");
    setCaseStudyData((prev) => {
      const newFaqList = { ...prev };
      newFaqList.sites = e;
      return newFaqList;
    });
  };
  console.log(caseStudyData, "caseStudyData");

  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">Case Study - Create</Text>
        <Button
          onClick={() => navigate(-1)}
          leftIcon={<BsListUl />}
          colorScheme="blue"
          variant="solid"
        >
          <Text fontStyle="italic">List</Text>
        </Button>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <Heading fontSize="medium" textTransform="uppercase" mb={3}>
          Create Case Study
        </Heading>
        <SimpleGrid columns={3} spacing={5}>
          <Box>
            <InputControl
              label="Heading"
              placeholder="Enter heading"
              onChange={(e) =>
                setCaseStudyData((prev) => ({
                  ...prev,
                  heading: e.target.value,
                }))
              }
              value={caseStudyData.heading}
            />
          </Box>
          <Box>
            <InputControl
              label="Date"
              type="date"
              placeholder="Enter name"
              onChange={(e) =>
                setCaseStudyData((prev) => ({
                  ...prev,
                  date: e.target.value,
                }))
              }
              value={caseStudyData.date}
            />
          </Box>
          <Box>
            <InputControl
              label="Name"
              placeholder="Enter name"
              onChange={(e) =>
                setCaseStudyData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              value={caseStudyData?.name}
            />
          </Box>
          <Box>
            <InputControl
              label="File (MAX:4MB)*"
              type="file"
              onChange={async (e) => {
                const image = await uploadImageHandler(e.target.files[0]);
                setCaseStudyData((prev) => ({ ...prev, file: image }));
              }}
            />
            {caseStudyData?.file && (
              <Image src={caseStudyData?.file} height={100} />
            )}
          </Box>
          <div>
            <input
              id="is_case"
              type="checkbox"
              checked={caseStudyData?.is_case}
              onChange={(e) =>
                setCaseStudyData((prev) => ({
                  ...prev,
                  is_case: e.target.checked,
                  is_whitepaper: false,
                }))
              }
            />
            <label htmlFor="is_case"> Is Case Study</label>
          </div>

          <div>
            <input
              id="is_whitepaper"
              type="checkbox"
              checked={caseStudyData?.is_whitepaper}
              onChange={(e) =>
                setCaseStudyData((prev) => ({
                  ...prev,
                  is_whitepaper: e.target.checked,
                  is_case: false,
                }))
              }
            />
            <label htmlFor="is_whitepaper"> is_whitepaper </label>
          </div>
        </SimpleGrid>
        <Box mt={5}>
          <Text mb="8px" fontWeight="bold">
            Short Description
          </Text>
          <Editor
            onChange={(content, delta, source, editor) => {
              setCaseStudyData((prev) => ({
                ...prev,
                shortDescription: editor.getHTML(),
              }));
            }}
            value={caseStudyData?.shortDescription}
            id="toolbar"
          />
          <SitesSelector
            selectedSite={caseStudyData.sites}
            handleSites={(e) => handleSites(e)}
          />
        </Box>

        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/case-study")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default AddCaseStudy;
