import axios from "axios";

export const createBlog = async (payload) => {
  try {
    const res = await axios.post(`create/blog`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const updateBlog = async (id, payload) => {
  try {
    const res = await axios.put(`update/blog/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllBlog = async () => {
  try {
    const res = await axios.get(`list/blog`);
    return res;
  } catch (error) {
    return error;
  }
};
export const deleteBlog = async (id) => {
  try {
    const res = await axios.delete(`delete/blog/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
export const getBlogById = async (id) => {
  try {
    const res = await axios.get(`list/blog/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
