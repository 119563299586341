import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, Suspense, lazy } from "react";
import InputControl from "../../components/Input";

import { useNavigate, useParams } from "react-router-dom";
import { createFAQ } from "../../services/FaqService";
import { BsListUl } from "react-icons/bs";
import SitesSelector from "../../components/SitesSelector";
import { defaultFaqsValue } from "./EditFaq";

const Editor = lazy(() => import("../../components/Editor"));

const CreateFAQs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [pageData, setPageData] = useState({});
  const [faqList, setFaqList] = useState([defaultFaqsValue]);
  const onSubmit = async () => {
    const payload = faqList.map((item) => {
      return {
        ...item,
        sites: item.sites.map((site) => site.value),
      };
    });
    console.log(payload, "faqListTest");

    const res = await createFAQ(faqList);
    console.log(res, "resData");
    if (res.status === 200) {
      toast({
        title: "FAQ created.",
        description: res?.response?.data?.message || "something went wrong",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // navigate("/faq/list");
    } else {
      toast({
        title: "Error.",
        description: "Something went wrong!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const localPageData = localStorage.getItem("productData");
    if (id) {
      if (localPageData) {
        const localPageDataObj = JSON.parse(localPageData);
        const pageData = localPageDataObj.find((page) => page.seoURL === id);

        console.log(pageData);

        setPageData(pageData);
      }
    }
  }, [id]);

  const onEditPageHandler = () => {
    const localPageData = localStorage.getItem("productData");
    if (localPageData) {
      const localPageDataObj = JSON.parse(localPageData);
      const newPageData = localPageDataObj.filter((page) => page.seoURL !== id);
      newPageData.push(pageData);
      localStorage.setItem("productData", JSON.stringify(newPageData));
    }
    toast({ title: "Product updated.", status: "success", duration: 9000 });
    navigate("/product/master");
  };

  const addFaq = () => {
    setFaqList((prev) => [...prev, defaultFaqsValue]);
  };
  const deleteFaq = (index) => {
    const newFaqList = faqList.filter((faq, i) => i !== index);
    setFaqList(newFaqList);
  };
  const handleSites = (e, index) => {
    console.log(e, "handleSites");
    setFaqList((prev) => {
      const newFaqList = [...prev];
      newFaqList[index].sites = e;
      return newFaqList;
    });
  };
  console.log(faqList, "faqListNewTesting");

  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">F A Qs - Create</Text>
        <Button
          onClick={() => navigate("/faq/list")}
          leftIcon={<BsListUl />}
          colorScheme="blue"
          variant="solid"
        >
          <Text fontStyle="italic">List</Text>
        </Button>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <Box
          mt={5}
          mb={5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="large" textTransform="uppercase">
            FAQS
          </Heading>
          <IconButton
            onClick={() => addFaq()}
            aria-label="Search database"
            icon={<AddIcon />}
          />
        </Box>

        {faqList.map((faq, index) => (
          <>
            <Box mb={5} mt={5} pos="relative">
              {faqList.length > 1 && (
                <IconButton
                  position="absolute"
                  right="5px"
                  top="5px"
                  onClick={() => deleteFaq(index)}
                  aria-label="Search database"
                  icon={<DeleteIcon />}
                />
              )}
              {console.log("faqqqqq", faq)}
              <Stack spacing={5} direction="row">
                <div>
                  <input
                    id="techsupport"
                    type="checkbox"
                    onChange={(e) => {
                      setFaqList((prev) => {
                        const newFaqList = [...prev];
                        newFaqList[index].techsupport = e.target.checked;
                        return newFaqList;
                      });
                    }}
                  />
                  <label htmlFor="techsupport"> Tech Support FAQs</label>
                </div>
                <div>
                  <input
                    id="pricingfaq"
                    type="checkbox"
                    onChange={(e) => {
                      setFaqList((prev) => {
                        const newFaqList = [...prev];
                        newFaqList[index].pricing = e.target.checked;
                        return newFaqList;
                      });
                    }}
                  />
                  <label htmlFor="pricingfaq"> Pricing FAQs</label>
                </div>
              </Stack>
            </Box>
            <Box>
              <InputControl
                label="Qestion"
                placeholder="Qestion"
                onChange={(e) =>
                  setFaqList((prev) => {
                    const newFaqList = [...prev];
                    newFaqList[index].questions = e.target.value;
                    return newFaqList;
                  })
                }
                value={faq.questions}
              />
            </Box>

            <Box mt={5} mb={5}>
              <FormLabel>Answer</FormLabel>
              <Suspense fallback={<div>Loading...</div>}>
                <Editor
                  onChange={(content, delta, source, editor) => {
                    setFaqList((prev) => {
                      const newFaqList = [...prev];
                      newFaqList[index].answer = content;
                      return newFaqList;
                    });
                  }}
                  value={faq?.answer}
                  id={`toolbar___${index}`}
                />
              </Suspense>
            </Box>
            <SitesSelector
              selectedSite={faqList[index].sites}
              handleSites={(e) => handleSites(e, index)}
            />
          </>
        ))}

        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                if (id) {
                  onEditPageHandler();
                } else {
                  onSubmit();
                }
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/faq/list")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default CreateFAQs;
