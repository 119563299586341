import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteProject, getAllProject } from "../../services/ProjectService";

const MasterProduct = () => {
  const navigate = useNavigate();
  const [pageContenList, setPageContentList] = useState([]);

  const getPageData = async () => {
    const res = await getAllProject();
    console.log(res, "resDAta");
    const resData = res.data;
    if (resData.status) {
      setPageContentList(resData.data);
    }
    // const pageData = localStorage.getItem("productData");
    // if (pageData) {
    //   const pageDataObj = JSON.parse(pageData);
    //   setPageContentList(pageDataObj);
    // }
  };
  useEffect(() => {
    getPageData();
  }, []);

  const deleteProjectHandler = async (id) => {
    const res = await deleteProject(id);
    const resData = res?.data;
    if (resData?.status) {
      getPageData();
    }
    // const localPageData = localStorage.getItem("productData");
    // if (localPageData) {
    //   const localPageDataObj = JSON.parse(localPageData);
    //   const newPageData = localPageDataObj.filter(
    //     (page) => page.seoURL !== seoURL
    //   );
    //   localStorage.setItem("productData", JSON.stringify(newPageData));
    //   getPageData();
    // }
  };

  console.log(pageContenList, "pageContenList");
  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px"> Product Masters - List</Text>
        <Button
          onClick={() => navigate("/product/master/create")}
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="solid"
        >
          Add Master Product
        </Button>
      </Box>
      <Box p={5}>
        <TableContainer background="#fff" boxShadow="base" p={5}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Sr. No.</Th>
                <Th>Name</Th>
                <Th>Image</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {pageContenList?.map((page, index) => (
                <Tr>
                  <Td>{index + 1}</Td>
                  <Td>
                    <a target="_blank" href={`/product/${page.seoURL}`} rel="noreferrer">
                      {page.masterData.name}
                    </a>
                  </Td>
                  <Td>
                    <Image height="65px" src={page.masterData.imageName} />
                  </Td>

                  <Td textAlign="right">
                    <HStack justifyContent="flex-end">
                      <IconButton
                        aria-label="Search database"
                        onClick={() =>
                          navigate(
                            `/product/master/edit/${page.masterData.seoURL}/${page.masterData.id}`
                          )
                        }
                        icon={<EditIcon />}
                      />
                      <IconButton
                        aria-label="Search database"
                        onClick={() => deleteProjectHandler(page.masterData.id)}
                        icon={<DeleteIcon />}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default MasterProduct;
