import axios from "axios";

export const createPage = async (payload) => {
  try {
    const res = await axios.post(`create/webpage`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const getAllPage = async () => {
  try {
    const res = await axios.get(`list/webpage`);
    return res;
  } catch (error) {
    return error;
  }
};
export const deletePage = async (id) => {
  try {
    const res = await axios.delete(`delete/webpage/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
export const getPageById = async (id) => {
  try {
    const res = await axios.get(`list/webpage/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
export const updatePage = async (id, payload) => {
  try {
    const res = await axios.put(`update/webpage/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
