// SitesSelector.js
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FormLabel, SimpleGrid, Box, Textarea, Text } from "@chakra-ui/react";
import makeAnimated from "react-select/animated";
import { getAllSites } from "../../services/SitesService";

// You can extract the animated components here
const animatedComponents = makeAnimated();

const SitesSelector = ({ selectedSite, handleSites }) => {
  const [siteList, setSiteList] = useState([]);

  const onChange = (e) => {
    handleSites(e);
  };

  const getSiteList = async () => {
    const res = await getAllSites();
    if (res) {
      const resData = res.data.data;
      let formattedData = resData.map((item) => ({
        value: item.id,
        label: item.name,
        ...item,
      }));
      setSiteList(formattedData);
    }
  };

  const getSitesSelecioniByArrayOfId = () => {
    if (typeof selectedSite?.[0] === "string") {
      const result = [];
      siteList.forEach((element) => {
        if (selectedSite.includes(element.value)) {
          result.push(element);
        }
      });
      return result;
    } else {
      return selectedSite;
    }
  };
  useEffect(() => {
    getSiteList();
  }, []);
  const selection = getSitesSelecioniByArrayOfId();
  console.log(selectedSite, siteList, selection, "selectedSite");
  return (
    <Box mt={15} mb={7}>
      <FormLabel>Sites</FormLabel>
      <Select
        value={selection}
        options={siteList}
        components={animatedComponents}
        isMulti
        onChange={onChange}
      />
    </Box>
  );
};

export default SitesSelector;
