import axios from "axios";

export const uploadImage = async (payload) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const res = await axios.post(`upload_image`, payload, config);
    return res;
  } catch (error) {
    return error;
  }
};
