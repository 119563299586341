import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteFaq, fetchAllFAQs } from "../../services/FaqService";

const FAQHeadings = () => {
  const navigate = useNavigate();
  const [faqList, setFaqList] = useState([]);

  const fetchAllFAQsHandler = async () => {
    const res = await fetchAllFAQs();
    setFaqList(res.data.data || []);
  };
  useEffect(() => {
    fetchAllFAQsHandler();
  }, []);

  const deletePage = async (id) => {
    const res = await deleteFaq(id);

    if (res) {
      fetchAllFAQsHandler()
    }
  };

  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">FAQs - List</Text>
      </Box>
      <Box p={5}>
        <TableContainer background="#fff" boxShadow="base" p={5}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th width={10}> Sr. No. </Th>
                <Th> Question </Th>
                <Th textAlign="right">  Action </Th>
              </Tr>
            </Thead>
            <Tbody>
              {console.log("faqList", faqList)}
              {faqList.map((item, index) => (
                <Tr>
                  <Td>{index + 1}</Td>
                  <Td>
                    {/* <a target="_blank" href={`/static/${item.seoURL}`}> */}
                    {item.questions}
                    {/* </a> */}
                  </Td>

                  <Td textAlign="right">
                    <HStack justifyContent="flex-end">
                      <IconButton
                        aria-label="Search database"
                        onClick={() => navigate(`/faq/edit/${item.id}`)}
                        icon={<EditIcon />}
                      />
                      <IconButton
                        aria-label="Search database"
                        onClick={() => deletePage(item.id)}
                        icon={<DeleteIcon />}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default FAQHeadings;
