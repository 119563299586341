import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../components/SEO";

const BlogRenderPage = () => {
  const { url } = useParams();
  const [pageContentData, setPageContentData] = useState({});
  const getPageData = () => {
    const pageData = localStorage.getItem("blogData");
    if (pageData) {
      const pageDataObj = JSON.parse(pageData);
      setPageContentData(pageDataObj.find((page) => page.seoURL === url));
    }
  };
  useEffect(() => {
    getPageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SEO
        title={pageContentData?.title}
        description={pageContentData?.metaDiscription}
        url={`/${pageContentData?.seoURL}`}
        ogTag={pageContentData?.ogTag}
      />
      <Box boxShadow="base" p={5} background="#fff" m={5}>
        <Heading>{pageContentData?.title}</Heading>
        <div
          dangerouslySetInnerHTML={{ __html: pageContentData?.pageContent }}
        />
        <Heading mt={5} mb={3} fontSize="large">
          Blog Content
        </Heading>
        <div
          dangerouslySetInnerHTML={{ __html: pageContentData?.blogContent }}
        />
      </Box>
    </>
  );
};

export default BlogRenderPage;
