import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Image,
  Select,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, Suspense, lazy } from "react";
import InputControl from "../../components/Input";

import { useNavigate, useParams } from "react-router-dom";
import {
  createDetailProduct,
  getAllProject,
  getProductDetailById,
  updateDetail,
} from "../../services/ProjectService";
import { uploadImage } from "../../services/GlobalService";
import { BsListUl } from "react-icons/bs";

const Editor = lazy(() => import("../../components/Editor"));

const CreateDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [pageData, setPageData] = useState({});
  const [productData, setProductData] = useState([]);
  const [productTerms, setProductTerms] = useState([]);

  const onSubmit = async () => {
    const {
      masterProduct,
      subHeader,
      contentImage,
      productContentDiscription,
    } = pageData;
    const payload = {
      masterProduct,
      contentImage: contentImage,
      subHeader,
      productContentDiscription,
      productTerms: productTerms,
    };
    if (id) {
      const res = await updateDetail(id, payload);
      const resData = res.data;
      if (res.status === 200) {
        toast({
          title: "Product Details updated.",
          description: resData.message || "somthing went wrong",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/product/details");
      } else {
        toast({
          title: "Error.",
          description: resData.message || "somthing went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      const res = await createDetailProduct(payload);
      const resData = res.data;
      if (resData.status) {
        toast({
          title: "Product Details created.",
          description: resData.message || "somthing went wrong",
          status: "success",
          isClosable: true,
        });
        navigate("/product/details");
      } else {
        toast({
          title: "Error.",
          description: resData.message || "somthing went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const getProductDetailByIdHandler = async (id) => {
    const res = await getProductDetailById(id);
    console.log(res.data.data, "resDAta");
    if (res.status === 200) {
      const resData = res.data.data;
      setPageData({
        ...pageData,
        masterProduct: resData?.masterProduct.id,
        masterData: resData?.masterData,
        contentImage: resData?.contentImage,
        subHeader: resData?.subHeader,
        productContentDiscription: resData?.productContentDiscription,
      });
      setProductTerms(resData?.productTerms);
    }
  };
  useEffect(() => {
    if (id) {
      getProductDetailByIdHandler(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getAllMasterProduct();
  }, []);

  const getAllMasterProduct = async () => {
    const res = await getAllProject();
    console.log(res, "resDAta");
    const resData = res.data;
    if (resData.status) {
      setProductData(resData.data);
    }
  };

  const addProductTerms = () => {
    setProductTerms([
      ...productTerms,
      {
        heading: "",
        subHeading: "",
        detailsImage: "",
        homeBannerImage: "",
        homeDescription: "",
        productDetailDescription: "",
      },
    ]);
  };

  const deleteProductTerms = (index) => {
    const newProductTerms = productTerms.filter((term, i) => i !== index);
    setProductTerms(newProductTerms);
  };
  const deleteProductTermsSubheading = (index) => {
    const _productTerms = [...productTerms];
    _productTerms.forEach((el) => {
      el.ProductTermSubHeading = el.ProductTermSubHeading.filter(
        (term, i) => i !== index
      );
    });

    setProductTerms(_productTerms);
  };

  const uploadImageHandler = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadImage(formData);
    console.log(res, "resData");
    if (res.status === 200) {
      return res.data.data.ImageUrl;
    }
  };

  const addProductTermSubHeading = (index) => {
    const _productTerms = [...productTerms];
    let productTermsSubHeading = [];
    _productTerms.forEach((el, i) => {
      if (i === index) {
        productTermsSubHeading.push({
          subHeading1: "",
          subHeading2: "",
          content: "",
          productTermSubHeadingImage: "",
        });
        if (el?.ProductTermSubHeading?.length > 0) {
          el.ProductTermSubHeading = [
            ...el.ProductTermSubHeading,
            ...productTermsSubHeading,
          ];
        } else {
          el.ProductTermSubHeading = productTermsSubHeading;
        }
      }
    });
    setProductTerms(_productTerms);
  };

  console.log(productTerms, "productTerms");

  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">Product Details - Create</Text>
        <Button
          onClick={() => navigate(-1)}
          leftIcon={<BsListUl />}
          colorScheme="blue"
          variant="solid"
        >
          <Text fontStyle="italic">List</Text>
        </Button>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <Heading fontSize="2x2" mb={3}>
          Create Products Details
        </Heading>
        <SimpleGrid columns={3} spacing={5}>
          <Box>
            <FormLabel>Products</FormLabel>
            <Select
              placeholder="Select Product"
              onChange={(e) => {
                setPageData((prev) => ({
                  ...prev,
                  masterProduct: e.target.value,
                }));
              }}
              value={pageData?.masterProduct}
            >
              {productData?.map((product, i) => (
                <option key={i} value={product?.masterData.id}>
                  {product?.masterData.name}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <InputControl
              label="Content Image"
              placeholder="Image"
              type="file"
              onChange={async (e) => {
                const image = await uploadImageHandler(e.target.files[0]);
                setPageData((prev) => ({
                  ...prev,
                  contentImage: image,
                }));
              }}
            />
            <Image src={pageData?.contentImage} />
          </Box>
          <Box>
            <InputControl
              label="Sub Header"
              placeholder="Enter Sub Header"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, subHeader: e.target.value }))
              }
              value={pageData.subHeader}
            />
          </Box>
        </SimpleGrid>

        <Box mt={5} mb={5}>
          <FormLabel>Description</FormLabel>
          <Suspense fallback={<div>Loading...</div>}>
            <Editor
              onChange={(content, delta, source, editor) => {
                setPageData((prev) => ({
                  ...prev,
                  productContentDiscription: editor.getHTML(),
                }));
              }}
              value={pageData?.productContentDiscription}
              id="toolbar"
            />
          </Suspense>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Heading fontSize="2x1">PRODUCT TERMS</Heading>
          <IconButton
            onClick={addProductTerms}
            aria-label="Search database"
            icon={<AddIcon />}
          />
        </Box>
        <Box>
          {productTerms?.map((productTerm, index) => (
            <Box
              p={5}
              boxShadow="base"
              background="#fff"
              m={5}
              position="relative"
              key={index}
            >
              <IconButton
                position="absolute"
                right="5px"
                top="5px"
                onClick={() => deleteProductTerms(index)}
                aria-label="Search database"
                icon={<DeleteIcon />}
                zIndex={1}
              />
              <SimpleGrid columns={2} spacing={5}>
                <Box>
                  <InputControl
                    label="Heading"
                    placeholder="Enter Heading"
                    onChange={(e) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].heading = e.target.value;
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm?.heading}
                  />
                </Box>
                <Box>
                  <InputControl
                    label="Sub Heading"
                    placeholder="Enter Sub Heading"
                    onChange={(e) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].subHeading = e.target.value;
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm?.subHeading}
                  />
                </Box>
                <Box>
                  <InputControl
                    label="Detail Image"
                    type="file"
                    onChange={async (e) => {
                      const newProductTerms = [...productTerms];
                      const image = await uploadImageHandler(e.target.files[0]);
                      newProductTerms[index].detailsImage = image;
                      setProductTerms(newProductTerms);
                    }}
                  />
                  <Image src={productTerm?.detailsImage} />
                </Box>
                <Box>
                  <InputControl
                    label="Home Banner Image"
                    type="file"
                    onChange={async (e) => {
                      const newProductTerms = [...productTerms];
                      const image = await uploadImageHandler(e.target.files[0]);
                      newProductTerms[index].homeBannerImage = image;
                      setProductTerms(newProductTerms);
                    }}
                  />
                  <Image src={productTerm.homeBannerImage} />
                </Box>
              </SimpleGrid>
              <Box mt={5} mb={5}>
                <FormLabel>Home Description</FormLabel>
                <Suspense fallback={<div>Loading...</div>}>
                  <Editor
                    onChange={(content, delta, source, editor) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].homeDescription = editor.getHTML();
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm?.homeDescription}
                    id={`toolbar_${index}`}
                  />
                </Suspense>
              </Box>
              <Box mt={5} mb={5}>
                <FormLabel>Product Details Description</FormLabel>
                <Suspense fallback={<div>Loading...</div>}>
                  <Editor
                    onChange={(content, delta, source, editor) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].productDetailDescription =
                        editor.getHTML();
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm?.productDetailDescription}
                    id={`toolbar__${index}`}
                  />
                </Suspense>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Heading fontSize="2x1">PRODUCT TERMS SUB HEADING</Heading>
                <IconButton
                  onClick={() => addProductTermSubHeading(index)}
                  aria-label="Search database"
                  icon={<AddIcon />}
                />
              </Box>
              {productTerm.ProductTermSubHeading?.map(
                (productTermSubHeading, i) => (
                  <Box
                    p={5}
                    boxShadow="base"
                    background="#fff"
                    m={5}
                    position="relative"
                    key={index}
                  >
                    <IconButton
                      position="absolute"
                      right="5px"
                      top="5px"
                      onClick={() => deleteProductTermsSubheading(i)}
                      aria-label="Search database"
                      icon={<DeleteIcon />}
                      zIndex={1}
                    />
                    <SimpleGrid columns={3} spacing={5}>
                      <Box>
                        <InputControl
                          label={`Sub Heading${i + 1}`}
                          placeholder={`Sub Heading${i + 1}`}
                          onChange={(e) => {
                            const newProductTerms = [...productTerms];
                            newProductTerms[index].ProductTermSubHeading[
                              i
                            ].subHeading1 = e.target.value;
                            setProductTerms(newProductTerms);
                          }}
                          value={productTermSubHeading?.subHeading1}
                        />
                      </Box>
                      <Box>
                        <InputControl
                          label={`Sub Heading${i}`}
                          placeholder={`Sub Heading${i}`}
                          onChange={(e) => {
                            const newProductTerms = [...productTerms];
                            newProductTerms[index].ProductTermSubHeading[
                              i
                            ].subHeading2 = e.target.value;
                            setProductTerms(newProductTerms);
                          }}
                          value={productTermSubHeading?.subHeading2}
                        />
                      </Box>
                      <Box>
                        <InputControl
                          label="Product Terms SubHeading Image"
                          type="file"
                          onChange={async (e) => {
                            const newProductTerms = [...productTerms];
                            const image = await uploadImageHandler(
                              e.target.files[0]
                            );
                            newProductTerms[index].ProductTermSubHeading[
                              i
                            ].productTermSubHeadingImage = image;
                            setProductTerms(newProductTerms);
                          }}
                        />
                        <Image
                          src={
                            productTermSubHeading?.productTermSubHeadingImage
                          }
                        />
                      </Box>
                    </SimpleGrid>
                    <Box mt={5} mb={5}>
                      <FormLabel>Content</FormLabel>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Editor
                          onChange={(content, delta, source, editor) => {
                            const newProductTerms = [...productTerms];

                            newProductTerms[index].ProductTermSubHeading[
                              i
                            ].content = editor.getHTML();
                            setProductTerms(newProductTerms);
                          }}
                          value={productTermSubHeading?.content}
                          id={`toolbar_${index}_${i}`}
                        />
                      </Suspense>
                    </Box>

                    <Box display="flex" justifyContent="space-between">
                      <Heading fontSize="2x1">
                        PRODUCT TERMS SUB HEADING
                      </Heading>
                      <IconButton
                        onClick={() => addProductTermSubHeading(index)}
                        aria-label="Search database"
                        icon={<AddIcon />}
                      />
                    </Box>
                  </Box>
                )
              )}
            </Box>
          ))}
        </Box>

        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/product/details")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default CreateDetails;
