import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteBanner, fetchBanner } from "../../services/BannerService";

const Banner = () => {
  const navigate = useNavigate();
  const [bannerDataList, setbannerDataList] = useState([]);

  const fetchAllBannerHandler = async () => {
    const res = await fetchBanner();
    setbannerDataList(res.data.data || []);
  };

  useEffect(() => {
    fetchAllBannerHandler();
  }, []);

  const deleteBannerHandler = async (id) => {
    // eslint-disable-next-line no-unused-vars
    const res = await deleteBanner(id);
    fetchAllBannerHandler();
  };

  return (
    <>
      <Box>
        <Box
          boxShadow="base"
          p={5}
          background="#fff"
          display="flex"
          justifyContent="space-between"
          alignContent="center"
        >
          <Text fontSize="22px">Banner - List</Text>
          <Button
            onClick={() => navigate("/banner/create")}
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
          >
            Add Banner
          </Button>
        </Box>
        <Box p={5}>
          <TableContainer background="#fff" boxShadow="base" p={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Sr. No.</Th>
                  <Th>Name</Th>
                  <Th>Image</Th>

                  <Th textAlign="right"> Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {bannerDataList.map((page, index) => (
                  <Tr>
                    <Td width="5%">{index + 1}</Td>

                    <Td>{page.name}</Td>
                    <Td>
                      <Image src={page.image} height="100px" />
                    </Td>

                    <Td textAlign="right">
                      <HStack justifyContent={"flex-end"}>
                        <IconButton
                          aria-label="Search database"
                          onClick={() => navigate(`/banner/edit/${page.id}`)}
                          icon={<EditIcon />}
                        />
                        <IconButton
                          aria-label="Search database"
                          onClick={() => deleteBannerHandler(page.id)}
                          icon={<DeleteIcon />}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default Banner;
