import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteSites, getAllSites } from "../../services/SitesService";


function Sites() {
    const navigate = useNavigate();
    const [pageContenList, setPageContentList] = useState([]);

    const getSiteData = async () => {
        const res = await getAllSites();
        const resData = res.data;
        setPageContentList(resData.data);
    };
    useEffect(() => {
        getSiteData();
    }, []);

    const deletePage = async (id) => {
        const res = await deleteSites(id);
        if (res) {
            getSiteData();
        }
    };
    return (
        <>
            <Box>
                <Box
                    boxShadow="base"
                    p={5}
                    background="#fff"
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                >
                    <Text fontSize="22px">Sites - List</Text>
                    <Button
                        onClick={() => navigate("/sites/create")}
                        leftIcon={<AddIcon />}
                        colorScheme="blue"
                        variant="solid"
                    >
                        Add New Site
                    </Button>
                </Box>
                <Box p={5}>
                    <TableContainer background="#fff" boxShadow="base" p={5}>
                        <Table size="sm">
                            <Thead>
                                <Tr>
                                    <Th> Sr. No. </Th>
                                    <Th> Title </Th>
                                    <Th> URL </Th>
                                    <Th textAlign={"right"}> Action </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {pageContenList.length ?

                                    pageContenList.map((page, index) => (
                                        <Tr>
                                            <Td>{index + 1}</Td>
                                            <Td>{page.name}</Td>
                                            <Td>
                                                <a target="_blank" href={`${page.url}`} rel="noreferrer">
                                                    {page.url}
                                                </a>
                                            </Td>
                                            <Td>
                                                <HStack justifyContent={"flex-end"}>
                                                    <IconButton
                                                        aria-label="Search database"
                                                        onClick={() =>
                                                            navigate(`/sites/edit/${page.id}`)
                                                        }
                                                        icon={<EditIcon />}
                                                    />
                                                    <IconButton
                                                        aria-label="Search database"
                                                        onClick={() => deletePage(page.id)}
                                                        icon={<DeleteIcon />}
                                                    />
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    ))

                                    :
                                    <Tr>
                                        <Td colSpan={4} textAlign={"center"}>
                                            <p> No Sites Found </p>
                                        </Td>
                                    </Tr>
                                }

                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </>
    )
}

export default Sites