import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import InputControl from "../../components/Input";
import { useNavigate, useParams } from "react-router-dom";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { uploadImage } from "../../services/GlobalService";
import { createContact, fetchContactById, upadteContact } from "../../services/ContactService";

const MangeForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [contactData, setContactData] = useState({});
  const [talkToSpecialist, setTalkToSpecialist] = useState([
    { heading: "", toll_free: "", isd_code: "", mobile: "" },
  ]);
  const [customerSupportList, setCustomerSupportList] = useState([
    { heading: "", address: "", support: "", email: "", contact_number: "" },
  ]);

  const uploadImageHandler = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadImage(formData);
    console.log(res, "resData");
    if (res.status === 200) {
      return res.data.data.ImageUrl;
    }
  };

  const addTalkToSpecialist = () => {
    setTalkToSpecialist((prev) => [
      ...prev,
      { heading: "", toll_free: "", isd_code: "", mobile: "" },
    ]);
  };
  const addCustomerSupport = () => {
    setCustomerSupportList((prev) => [
      ...prev,
      { heading: "", address: "", support: "", email: "", contact_number: "" },
    ]);
  };

  const removeTalkToSpecialist = (index) => {
    const data = [...talkToSpecialist];
    data.splice(index, 1);
    setTalkToSpecialist(data);
  };
  const removeCustomerSupportList = (index) => {
    const data = [...customerSupportList];
    data.splice(index, 1);
    setCustomerSupportList(data);
  };

  const fetchContactByIdHandler = useCallback(async () => {
    const res = await fetchContactById(id);
    let data = res.data.data[0]
    setContactData(data);
    setTalkToSpecialist([data?.talk_to_specialist]);
    setCustomerSupportList([data?.customer_support]);
  }, [id]);

  useEffect(() => {
    if (id) fetchContactByIdHandler();
  }, [fetchContactByIdHandler, id]);

  console.log(contactData, "contactData");

  const onSubmit = async () => {
    const {
      banner_image,
      header,
      id,
      subheading,
      customer_support,
      talk_to_specialist
    } = contactData;
    const payload = {
      banner_image,
      header,
      subheading,
      customer_support: customer_support || customerSupportList,
      talk_to_specialist: talk_to_specialist || talkToSpecialist
    };
    if (id) {

      const res = await upadteContact(id, payload);
      const resData = res.data;
      if (res.status === 200) {
        toast({
          title: "Product Details updated.",
          description: resData.message || "somthing went wrong",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/mange-contact-us");
      }
      else {
        toast({
          title: "Error.",
          description: resData.message || "somthing went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      const res = await createContact(payload);
      const resData = res.data;
      if (resData.status) {
        toast({
          title: "Product Details created.",
          description: resData.message || "somthing went wrong",
          status: "success",
          isClosable: true,
        });
        navigate("/mange-contact-us");
      }
      else {
        toast({
          title: "Error.",
          description: resData.message || "somthing went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

  }

  return (
    <>
      {console.log("contactData", contactData)}
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">Manage Contact Us - Edit</Text>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <Heading fontSize="medium" textTransform="uppercase" mb={3}>
          Edit Contact Us
        </Heading>
        <SimpleGrid columns={3} spacing={5}>
          <Box>
            <InputControl
              label="Header"
              placeholder="Enter header"
              onChange={(e) =>
                setContactData((prev) => ({ ...prev, header: e.target.value }))
              }
              value={contactData.header}
            />
          </Box>
          <Box>
            <InputControl
              label="Sub Heading"
              placeholder="Enter sub heading"
              onChange={(e) =>
                setContactData((prev) => ({
                  ...prev,
                  subheading: e.target.value,
                }))
              }
              value={contactData?.subheading}
            />
          </Box>
          <Box>
            <InputControl
              type="file"
              label="Image File"
              onChange={async (e) => {
                const image = await uploadImageHandler(e.target.files[0]);
                setContactData((prev) => ({ ...prev, banner_image: image }));
              }}
              accept="image/png, image/gif, image/jpeg"
            />
          </Box>
        </SimpleGrid>
        <Box mt={10}>
          <Box
            mt={5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading fontSize="medium" textTransform="uppercase">
              Talk to a Specialist
            </Heading>
            <IconButton
              onClick={() => addTalkToSpecialist()}
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Box>

          {talkToSpecialist?.map((item, index) => (
            <Box position="relative">
              {index !== 0 && (
                <IconButton
                  position="absolute"
                  right="5px"
                  top="-15px"
                  zIndex={1}
                  onClick={() => removeTalkToSpecialist(index)}
                  aria-label="Search database"
                  icon={<DeleteIcon />}
                />
              )}

              <SimpleGrid columns={2} spacing={5} mt={5}>
                <Box>
                  <InputControl
                    label={`Heading #${index + 1}`}
                    placeholder="Enter heading"
                    onChange={(e) =>
                      setTalkToSpecialist((prev) => {
                        const data = [...prev];
                        data[index].heading = e.target.value;
                        return data;
                      })
                    }
                    value={item?.heading}
                  />
                </Box>
                <Box>
                  <InputControl
                    label={`Toll Free #${index + 1}`}
                    placeholder="Enter toll free "
                    onChange={(e) =>
                      setTalkToSpecialist((prev) => {
                        const data = [...prev];
                        data[index].toll_free = e.target.value;
                        return data;
                      })
                    }
                    value={item?.toll_free}
                  />
                </Box>
                <Box>
                  <InputControl
                    label={`ISDCODE #${index + 1}`}
                    placeholder="Enter ISD code "
                    onChange={(e) =>
                      setTalkToSpecialist((prev) => {
                        const data = [...prev];
                        data[index].isd_code = e.target.value;
                        return data;
                      })
                    }
                    value={item?.isd_code}
                  />
                </Box>
                <Box>
                  <InputControl
                    label={`Mobile #${index + 1}`}
                    placeholder="Enter mobile "
                    onChange={(e) =>
                      setTalkToSpecialist((prev) => {
                        const data = [...prev];
                        data[index].mobile = e.target.value;
                        return data;
                      })
                    }
                    value={item?.mobile}
                  />
                </Box>
              </SimpleGrid>
            </Box>
          ))}
        </Box>
        <Box mt={10}>
          <Box
            mt={5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading fontSize="medium" textTransform="uppercase">
              Customer Support
            </Heading>
            <IconButton
              onClick={() => addCustomerSupport()}
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Box>

          {customerSupportList?.map((item, index) => (
            <Box position="relative">
              {index !== 0 && (
                <IconButton
                  position="absolute"
                  right="5px"
                  top="-15px"
                  zIndex={1}
                  onClick={() => removeCustomerSupportList(index)}
                  aria-label="Search database"
                  icon={<DeleteIcon />}
                />
              )}

              <SimpleGrid columns={2} spacing={5} mt={5}>
                <Box>
                  <InputControl
                    label={`Heading #${index + 1}`}
                    placeholder="Enter heading"
                    onChange={(e) =>
                      setCustomerSupportList((prev) => {
                        const data = [...prev];
                        data[index].heading = e.target.value;
                        return data;
                      })
                    }
                    value={item?.heading}
                  />
                </Box>
                <Box>
                  <InputControl
                    label={`Address #${index + 1}`}
                    placeholder="Enter address "
                    onChange={(e) =>
                      setCustomerSupportList((prev) => {
                        const data = [...prev];
                        data[index].address = e.target.value;
                        return data;
                      })
                    }
                    value={item?.address}
                  />
                </Box>
                <Box>
                  <InputControl
                    label={`Support #${index + 1}`}
                    placeholder="Enter support "
                    onChange={(e) =>
                      setCustomerSupportList((prev) => {
                        const data = [...prev];
                        data[index].support = e.target.value;
                        return data;
                      })
                    }
                    value={item?.support}
                  />
                </Box>
                <Box>
                  <InputControl
                    label={`Email #${index + 1}`}
                    placeholder="Enter email "
                    onChange={(e) =>
                      setCustomerSupportList((prev) => {
                        const data = [...prev];
                        data[index].email = e.target.value;
                        return data;
                      })
                    }
                    value={item?.email}
                  />
                </Box>
                <Box>
                  <InputControl
                    label={`Contact Number #${index + 1}`}
                    placeholder="Enter contact number "
                    onChange={(e) =>
                      setCustomerSupportList((prev) => {
                        const data = [...prev];
                        data[index].contact_number = e.target.value;
                        return data;
                      })
                    }
                    value={item?.contact_number}
                  />
                </Box>
              </SimpleGrid>
            </Box>
          ))}
        </Box>

        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/mange-contact-us")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default MangeForm;
