import axios from "axios";

export const fetchContactList = async () => {
  try {
    const res = await axios.get(`list/contact-us`);
    return res;
  } catch (error) {
    return error;
  }
};
export const fetchContactById = async (id) => {
  try {
    const res = await axios.get(`list/contact-us/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const createContact = async (payload) => {
  try {
    const res = await axios.post(`create/contact-us`, payload);
    return res;
  } catch (error) {
    return error;
  }
};


export const upadteContact = async (id, payload) => {
  try {
    const res = await axios.put(`update/contact-us/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
